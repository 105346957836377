<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-card-title class="mb-1">
          ลืมรหัสผ่าน? 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          กรอกอีเมลที่เคยสมัครไว้ เพื่อรับลิงก์สำหรับเปลี่ยนรหัสผ่าน
        </b-card-text>

        <!-- form -->
        <validation-observer ref="resetPasswordForm">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- email -->
            <b-form-group
              label="อีเมลสำหรับเข้าสู่ระบบ"
              label-for="forgot-password-email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="forgot-password-email"
                  v-model="userEmail"
                  :state="errors.length > 0 ? false:null"
                  name="forgot-password-email"
                  placeholder="ตัวอย่าง : umoregame888@mail.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- submit button -->
            <b-overlay :show="isResettingPassword">
              <b-button
                variant="primary"
                block
                type="submit"
              >
                ส่งลิงก์รีเซ็ตรหัสผ่าน
              </b-button>
            </b-overlay>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'auth-login'}">
            <feather-icon icon="ChevronLeftIcon" /> กลับไปหน้าล๊อกอิน
          </b-link>
        </b-card-text>
      </b-card>
      <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>
 
<script>
import { required, email } from '@validations'
import { mapActions, mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  data() {
    return {
      userEmail: '',
      // validation
      required,
      email,
    }
  },
  computed: {
    ...mapState({
      isResettingPassword: (state) => state.user.isResettingPassword,
      isResetPasswordSuccess: (state) => state.user.isResetPasswordSuccess,
    }),
  },
  watch: {
    isResetPasswordSuccess(val) {
      if (val === true) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'ส่งคำขอเปลี่ยนรหัสสำเร็จ',
            icon: 'MailIcon',
            variant: 'success',
            text: 'กรุณาเช็คข้อความในอีเมลของคุณ',
          },
        })
      }
    },
  },
  methods: {
    ...mapActions(['resetPassword']),
    validationForm() {
      this.$refs.resetPasswordForm.validate().then((success) => {
        if (success) {
          this.resetPassword(this.userEmail)
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
